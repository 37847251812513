export default{

    props:{
        resource:{required:true},
        // value:{required:true},
        // options:{
        //     type:Array,
        //     default:() => ['haha','hehe']
        // },
       placeholder:{default:'select one'}
    },
    data(){
        return{
            active:0,
        }
    },
    computed:{
        value:{
            get(){
                return this.resource.selected;
            },
            set(val){
                this.resource.selected = val
            }
        },
        options(){
            return this.resource.options;
        },
        selectedValue(){
            // const activ = this.options[this.active]
            return this.options.indexOf(this.value)
            // return (activ.value == null)?activ:activ.value
        },
        selectedKey(){
            
            const ao = this.options[this.value]
            let key =  ao?(ao.name||ao):this.placeholder

            return this.$prettify(key)
        },
        coptions(){
         
            return  this.options.map(o => (
                typeof(o) == 'string'?{value:o,name:this.$prettify(o)}:o
            ))
        }
    },
    methods:{
        selectItem(o,k){
            this.active=k;
            this.value = k;
            this.expand=false;
            this.$emit('change',k)
        },
        equalsValue(o){
            if(o==this.value||o.value==this.value){return true;}
            else if((o.value != null) && o.value._id && (this.value != null) && this.value._id)
            {return o.value._id == this.value._id}
        }
    },
    mounted(){
        if(!this.value && !this.form && this.options.length){
            let value = this.options[0].value
            value = value != null ? value : this.options[0]
            this.$emit('input',value);
        }
    }
}