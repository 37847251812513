<template>
  <div class="sel-box d-flex flex-column position-relative"
  :class="form?'shadow-none ':''"
  @click="$emit('click')"
  >
      <div ref="button"
      class=""
       :class="(expand?'invert  ':'  ')+'  '+
       (form?'p-2   bg-input   -gray':'')+' '+buttonClass"
       :style="buttonStyle"
        @click="showDropdown"
      >
            <p :class="textClass" class="m-0">
                {{selectedKey}}
            </p>
            <i  :class="triangleClass+' '" style="font-size:1.2em"></i>
      </div>
      <!-- <div class="position-absolute scaffold overflow-visible" > -->
        <div v-show="expand"
            ref="popup"
            :class="dropClass+' '+(form?' -gray ':'')" 
            v-click-outside="clickedOutside"
        >
            <div 
                v-for="(o,k) in coptions" :key="k"
                :class="optionClass+' '+''"
                @click="selectItem(o,k)"
            >
                {{$prettify(o.name||o)}}
            </div>
        </div>
      <!-- </div> -->
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import '@/components/special/ClickOutside'
import selectMixin from '@/mixins/selectableMixin';
export default {
    mixins:[selectMixin],
    props:{
 
        form:{default:true},
        aclass:{default:''},
        textClass:{},
        buttonStyle:{},
        buttonClass:{
            default:`sel-button d-flex align-items-center 
                shadow-none   p-2 pl-3 pr-2 w-100`
        },
        dropClass:{default:'sel-dropdown rounded-bottom position-fixed bg-light'},
        triangleClass:{default:'fas ml-auto ml-3 mr-1 fa-caret-down '},
        optionClass:{default:'sel-option pl-3 cursor-pointer'}
    },
    data(){
        return{
             expand: false,
             popper:null,
        }
    },
    computed:{

    },
    methods:{
        showDropdown(){
            setTimeout(() => {
                this.expand=true;
                this.setPosition();

            }, 100);
        },
        clickedOutside(){
            this.expand = false;
        },
        setPosition(){
            const btn = this.$refs.button
            const drp = this.$refs.popup
            if(btn && drp){
                const rect = btn.getBoundingClientRect()
                // drp.style.left =(0)+'px';
                // drp.style.top = (rect.height)+'px';
                drp.style.width = (rect.width)+'px';
            }
         
        }
    },
    mounted(){
        const button = this.$refs.button
        const popup = this.$refs.popup


        this.popper = createPopper(button, popup, {
                placement: 'bottom-start',
                modifiers: [
                {
                    name: 'flip',
                    enabled: false,
                },
                
            ],
        });

     
    },
    beforeUpdate(){
        if(this.popper){
            this.popper.update();
        }
    },
    created(){
        window.addEventListener('resize',this.setPosition)
    }
}
</script>
<style>
.sel-box{
    font-size: 0.9em;
    --color: rgb(212, 184, 56);
    --color: var(--pri-light);
    min-width: 8em;
    cursor: pointer;
}
.sel-dropdown{
    z-index: 1;
    border: 1px solid var(--color);
    border-top: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.308);
    max-height: 20em;
    overflow-y: auto;
}
.sel-option:hover{
    background-color: var(--color);
    color: white;
}
.sel-option{
    padding-top: 6px;
    padding-bottom: 6px;
   
    text-align: left;
    user-select: none;
}
.sel-button{
     
    cursor: pointer;
    border-bottom: 2px solid lightblue;
    /* font-weight: bold; */
    color: var(--color);
    user-select: none;
}
.sel-button.invert{
    /* color: white; */
    /* background-color: var(--color); */
    font-weight: bold;
    border: 1px solid var(--color);
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

}
.sel-button > i{
    color: var(--gray);
   
}
.sel-button.invert > i{
    color: var(--pri-light);
   
}

.form-select{
    border: 1px solid var(--gray);
    color: var(--dark-gray);
}

.scaffold{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none;
}
</style>
