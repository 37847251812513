<template>
  <div class="radio-select d-flex dont-select flex-shrink-0"
  :class="button?'':'flex-wrap '"
  >

      <div v-for="(o,k) in coptions" :key="k"
        class=" d-flex cursor-pointer align-items-center"
        :class="((k==value)?'active':' ')+' '+
        (button?'radio-button':'radio-norm')+' '+
        (small?'small':'')"
         @click="selectItem(o,k)"
      >
        <div v-if="!button" class="mr-2 radio-circle">
          <div class="radio-dot" v-show="(value==k)"></div>
        </div>
          {{(o.name)}}
      </div>

  </div>
</template>

<script>
import selectMixin from '@/mixins/selectableMixin';

export default {
    mixins:[selectMixin],
    props:{
        button:{default:false,type:Boolean},
        small:{default:false,type:Boolean}
      
     },
    data(){
      return{
       }
    },
    methods:{
   
    }
}
</script>

<style>

.radio-select{
  --b-radius: 4px;
  --color: var(--pri);
  --b-color: var(--dark-gray);
  --b-color: var(--gray);
  /* --b-width: 1px; */
}
.radio-button{
  border: 1px solid rgba(0, 0, 0, 0.13);
  cursor: pointer;
  border-top-width: 0;
  border-bottom-width: 0;

  border-right-width: 0;
  padding: 6px 16px;
  background-color: rgba(0, 0, 0, 0.109);
  min-height: 1.8em;
}
.radio-norm{
  padding: 4px 6px;
}
.radio-button.normal{
  font-size: 0.9em;
}
.radio-button.active{
  border-color: var(--color);
  /* background-color: var(--color); */
  background: var(--gradient);
  color: white;
  font-weight: bold;
 
}
.radio-norm > .radio-circle{
  width: 1.0em;
  height: 1.0em;
  border-radius: 50%;
  border: 1px solid var(--b-color);
  padding: 2px;
}
.radio-norm.active > .radio-circle{
    
  border-color: var(--pri-light);
 
}
.radio-dot{
  width: 100%;
  height: 100%;
  background-color: var(--pri-light);
  border-radius: 50%;
   box-shadow: 0 0 8px var(--shadow);

}
.radio-button:last-child{
  border-top-right-radius: var(--b-radius);
  border-bottom-right-radius: var(--b-radius);

}
.radio-button:first-child{
  border-left-width: 0;
  border-top-left-radius: var(--b-radius);
  border-bottom-left-radius: var(--b-radius);
}
</style>