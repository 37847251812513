<template>
      <Modal
        v-if="show"
      >

        <div class="rounded mx-3 p-4 px-5 d-flex flex-column">
                    <div class="d-flex  mx-auto mt-3 align-items-center">

                        <img src="/images/shield.png" class="vloading mb-3" alt=""
                        style="width:60px;">
                    </div>

                    <p class="text-bluegray">
                        {{message}}
                    </p>
        </div>

      
      </Modal>
</template>

<script>
import Modal from '../common/Modal'
export default {
  components:{
    Modal
  },
    props:{
        show:{default:false},
        message:{default:'loading...'}
    }
}
</script>

<style>

</style>