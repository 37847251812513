<template>
    <div class="d-flex flex-column">


    <button class="next-button rounded position-relative d-flex flex-row
    justify-content-between align-items-center pr-3 "
    :class="disabled?'disabled':''"
        v-on="$listeners"
    >
        <div></div>
        <p class="mb-0 p-2 font-weight-bold text-uppercase ">
            {{text}}
        </p>
    
        <i v-if="hasNext" class=" fas fa-arrow-right next-arrow "></i>
        <i v-else></i>

    </button>

    </div>
</template>

<script>
export default {
    props:{
        text:{default:'Next'},
        hasNext:{default:true},
        disabled:{type:Boolean}
    }

}
</script>

<style>

    .next-button{
        background-color: var(--scolor);
        background-color: var(--green);
        /* background: linear-gradient(to bottom right,rgb(58, 116, 143),rgb(82, 140, 216)); */
        border: none;
        outline: none;
    }
    .next-button:focus{
        outline: none;
    }
    .next-button:active{
        background-color: rgb(32, 151, 141);
        color: lightblue;
        /* box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.589); */
    }
    .next-button.disabled:active{
        background-color: var(--green);
        color: unset;
    }
    .next-button > *{
        font-size: 1.2em;
    }
    .next-button > i{
        right: 0;
    }
    .next-arrow {
        font-size: 1.5em;
    }

    .disabled{
        filter: saturate(0.7) opacity(0.6);
    }

</style>