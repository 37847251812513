<template>
  <div class="d-flex flex-row mb-0 flex-grow-1" id="page-indicator">

    <div  v-for="(i,k) in sections.slice(0,4)/*.slice(0,currentSection+1)*/" :key="i.title"
        class=" d-flex flex-row align-items-center" :class="k == currentSection ? 'mr-auto' : ''"
    >

      <div class="page-circle d-flex flex-column justify-content-center " 
       :class="k == currentSection?'current':(k<currentSection?'completed':'')"
      >
      <p v-if="k == 3" class="mb-0 " >
        <i class="fas fa-check" >

        </i>
      </p>
        <p v-else class="mb-0 circle-text" >
       
          {{k+1}}
       
        </p>
      
      </div>
      <h6 v-if=" k == currentSection" class="section-title font-weight-bold  m-1 align-center" >
        {{i.title}}
      </h6>

    </div>



      <!-- <div class="page-circle"
        v-for="(i,k) in sections.slice(currentSection+1)" :key="i.title"
      >
        {{k+currentSection+1+1}}
      </div> -->


  </div>
</template>

<script>
export default {
    props:['sections','currentSection'],
    data(){
        return{

        }
    }


}
</script>

<style scoped>

#page-indicator{
  --circle-color: rgb(43, 70, 104);
  --done-color: var(--green);
}

.page-circle{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid var(--circle-color);
    margin-right: 8px;
}
.circle-text{
  font-size: 0.8em;
  color: var(--circle-color);
  font-weight: bold;
}
.section-title{
  color: var(--text-color);
}
.current{
  background-color: var(--circle-color);
}
.current > p{
  color:white;
}
.completed {
  border-color: var(--done-color);
  background-color: var(--done-color);

}
</style>