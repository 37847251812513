<template>
  <div class=" position-relative "
    id="picture-progress"
  >
    <img src="/images/background/Rectangle.svg" alt="background"
      id="background-image"
    >


    <!-- <img id="tree1" src="/images/background/tree1.svg" alt=""> -->


    <img 
      v-for="i in cimages" :key="i.id"
      :id="i.id" 
      class="position-absolute progress-picture"
      :class="i.id=='city'?'grayscale':''"
      :src="imgUrl(i.id)" alt=""
      v-show="page_num >= i.min"
    >


  </div>
</template>

<script>
const female_min  = 16;
const male_min = 12;
export default {
  props:['currentSection','sections','currentPage','fdata'],

  data(){
    return{

      images:[
        {
          id:'city',min:13,
        },
        {
          id:'bg_props',min:0,
        },
        {
          id:'home',min:33,
        },  
        {
          id:'male_user',min:male_min,
        },
        {
          id:'female_user',min:female_min,
        },
        {
          id:'child',min:22,
        },
        {
          id:'parent',min:23,
        },

      ]
    }
  },
  computed:{
    page_num(){
      return (this.currentSection+1) * 10 + this.currentPage;
    },
    cimages(){
      let c = this.images;
      const mindex = c.find(i => i.id=='male_user');
      const findex = c.find(i => i.id=='female_user');

      if(this.fdata.gender.selected == 0){
        mindex.min = female_min;
        findex.min = male_min;
      }else{
        mindex.min =  male_min;
        findex.min = female_min;
      }
      // 
      return c;
    }

  },methods:{
    imgUrl(id){
      if(id == 'city'){
        const fc = this.fdata.city;
   
        let cityName = (fc.options[fc.selected]).name;
        // cityName = (cityName=='Other')?'Mumbai':cityName
        return `/images/cities/${cityName}.svg`
      }
      return `/images/background/${id}.png`;
    }
  }


}
</script>

<style>
.progress-picture{
  width: 6%;
  bottom: 0;
  left: 0%;
  transform-origin: bottom;
  transform: scale(0.7);
}

#picture-progress{
  width: 100%;
  /* max-height: 138px; */
  /* border: 1px solid yellow; */

}

#background-image{
  min-height: 128px;
  /* max-height: 184px; */
  max-height: 28vh;
  /* border: 2px solid red; */
  width: 100%; 
}


@media screen and (max-width: 576px) {
  #picture-progress{
    width: 100vw;
  }
  .progress-picture{
    transform: scale(0.82);
  }
  #background-image{
    min-height: 0px;
    max-height: 30vh;

    /* max-height: 24vh; */

  }
}


#bg_props{
  width: 90%;
  left: 5%;
  bottom: 0;
}
#male_user{
  left: 70%;   
}
#female_user{
  left: 64%;   
}
#child{
  left: 20%;   
  width: 4.5%;
}
#parent{
  left: 80%; 
  width: 5%;  
}

#home{
  width: 50%;
  left: 16%;
}
#city{
  left: 4%;
  width: 50%;
  max-height: 54%;;
   
}

.grayscale{
  filter: grayscale(80%);
  opacity: 0.3;
}

</style>