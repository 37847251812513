<template>
  
  <p class="  text-muted text-left question-text mt-2 ">
      <slot/>
        <!-- {{text}} -->
  </p>

</template>

<script>
export default {

    props:{
        text: {type:String}
    }

}
</script>

<style>
.question-text{
  font-size: 0.9em;
  margin-bottom: 10px;
}
@media screen and (max-width:380px) {
    .question-text{
      font-size: 0.85em;
    }
}

</style>