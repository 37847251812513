<template>
    
    <div class="form-radio d-flex flex-row justify-content-around
        align-items-end
     mx-2" :class="className" >

        <div class=" d-flex flex-column  mb-2 mb-sm-2"
            v-for="(o,k) in value.options" :key="o.name"
            @click="value.selected = k"
        >
            <h1 class="  mb-0  " v-show="k == value.selected">

                <i class="fas fa-check   " style="color:var(--blue)">

                </i>
            </h1>

            <img :src="o.img" 
            v-if="o.img"
            :style="{}/*imageStyle(k)*/"
            alt="no image" class="choice-image mx-auto cursor-pointer">

            <button
                
                class="choice-button border-0 rounded px-2 
                shadow font-weight-bold
                  text-uppercase flex-grow-1 mt-2"
                :class="buttonClass(k)"
                :style="buttonStyle(o,k)"
                
            >
                {{o.name}}
            </button>
        </div>

    </div>

</template>

<script>
export default {
    props:{
        size:{default:1.0},
        value:{type:Object,required:true},
        direction:{
            default:"row"
        },
        options:{
            type:Array,
        },
        

    },
    data(){
        return {
            

        }
    },
    computed:{

        className(){
            return ''
            /*
            return "flex-"+this.direction+" "
             +(this.direction=='column'?'align-items-center':'align-items-stretch');
             */
        },
    },
    methods:{
        
        buttonClass(k){
            const selected = k == this.value.selected;
            return selected ? " selected-radio" : " normal-radio";
        },
        imageStyle(k){
            const isInactive = k!=this.value.selected;
            return isInactive?{'filter':'grayscale(1)'}:{}
        },
        buttonStyle(o/*o,k*/){
            const style = {'font-size':this.size+'em'}
            if(o.color){
                style['background-color'] =   `var(--${o.color})` ;
            }
            return style;
        }
    },
    mounted(){
      
        
    }

}
</script>

<style>


.choice-button{

}


.choice-image{
    height: 86px;
    width: 86px;
}

</style>