<template>

  <div class="d-flex align-items-center mb-1  slider"
    :class="heading?'':'ml-2'"
  >

    <button class="slider-button bg-transparent font-weight-bold p-2 rounded  mt-4" v-if="heading"
      @click="$emit('toggle')"
     >
        {{heading}}
    </button>

    <div class="flex-grow-1">
    <div class=" d-flex flex-row align-items-end flex-grow-1">

      <div class="slider-line " style="width:24px;margin-bottom:38px;" v-if="heading"></div>

      <div class=" position-relative flex-grow-1" 
        ref="slider"
        
      >
        <div class="indicator center-item mb-2 position-relative rounded" 
        :class="visibility2"
        :style="thumbStyle">
            
            <p class="mb-0 font-weight-bold text-white">
              {{(typeof value == 'number' ? value : 0).toFixed(0)}}
              <i class="fas arrow-down fa-caret-down position-absolute" />
            </p>
            

        </div>
        <div class="d-flex flex-row justify-content-between position-relative"
                  :class="visibility"
        >
            
            <p v-for="(i,k) in ticks" :key="i" class="mb-1 tick-text 
            "
              :class="(k==0&&!heading)?'':'position-absolute center-item'"
              :style="tickPos(i)"
            >
              {{i}}
            </p>
            <p class="invisible m-0">
              0
            </p>
           
        </div>

        <div>
            <div class="d-flex flex-row justify-content-between"
              :class="visibility"
            >
                <div v-if="!heading" class="tick-mark"></div>
                <div v-else></div>
                <div></div>
                <!-- <div class="tick-mark " /> -->
          </div>
        </div>
        
        <div class="slider-line">
          <div class="d-flex flex-row justify-content-end position-absolute w-100"
            :class="visibility2"
          >

              <p class="slider-label mt-1 mb-0 center-item2">
                {{label}}
              </p>

          </div>

        </div>

        <div class="wrapper">

          <div class="thumb center-item position-relative d-flex
          justify-content-center align-items-center" :style="thumbStyle"
                  :class="visibility"
                  @mousedown="mouseStart"
                  @touchstart="touchStart"
                  @touchend="mouseEnd"
          >
              <i class="fas arrow-up fa-caret-up position-absolute" />
              <div class="grip-line " v-for="i in [1,2,3]" :key="i" 
              :style="{'background-color':visibility2?'lightslategray':'white'}">
                
              </div>
          </div>
        </div>

      </div>
      <div class="slider-line "
        v-if="active"
       style="width:32px;margin-bottom:38px;"></div>


    </div>


        

      </div>


    <!-- <div class="slider-button bg-transparent 
    font-weight-bold p-0 rounded  mt-4"  
   
     > -->
        <input type="text" 
                ref="input"
        maxlength="3"
         :value="value"
         @input="handleInput"
         @change="handleChange"
        class="  slider-input rounded mt-4
        font-weight-bold  pr-1 py-1"
        v-if="active"
        :class="(value<10?'pl25':'pl-1')"
        
         />
    <!-- </div> -->

    <!-- <a-slider class="pika" :min="0" :max="100" @change="handleChange" :value="tvalue" /> -->
  </div>

</template>

<script>
export default {

  data(){
    return{
      mdown:false,
      slider:null,
      scolor:null,
      scolor2:null
    }
  },

  props:{
    value:{type:Number,default:12},

    min:{default:0},
    max:{default:100},
    steps:{default:5},
    discrete:{default:true},
    step:{default:1},

    label:{type:String,default:'years'},
    heading:{type:String},

    active:{default:true},
    autofocus:{default:false}
    
  },
  computed:{
    percentage(){
      return (this.value-this.min)/(this.max-this.min)*100;
    },
    thumbStyle(){
      return {
        "left": "calc("+this.percentage+"% )"
      }
    },
    ticks(){
      const arr = [];
      let stepSize = ((this.max-this.min)/this.steps);
      stepSize = Math.round(this.max/this.steps);
     
      arr.push(this.min)

      for(let i = 0;i < this.max;i += stepSize){
        const newTick = i;
        if(newTick >= this.min && Math.abs(newTick-this.min)>=(stepSize/4)) {
          arr.push(newTick)
        }
      }
      // let order = 10;
      // for(let i = this.min;i <= this.max;i += stepSize){
      //   let roundTick = Math.round(i);
      //   // if(i != this.min && i < this.max){
      //   //   roundTick = Math.round(roundTick / order)*order;
      //   // }
      //   arr.push(roundTick)
      // }
      arr.push(this.max)
      return arr;
    },
    visibility(){
      return this.active ?'':'invisible';
    },
    visibility2(){
      return this.active && this.value>0 ?'':'invisible';
    }
  },
  mounted(){
    if(this.autofocus && this.active && screen.width>screen.height){
      this.$refs.input.focus();
    }
    // this.slider = this.$el.getElementsByClassName('slider')[0];
    this.slider = this.$refs.slider;

    const sliderc = this.$el;

    this.scolor = getComputedStyle(sliderc).getPropertyValue('--scolor');
    this.scolor2 = getComputedStyle(sliderc).getPropertyValue('--scolor2');

    this.toggleColor(this.active && this.value !=0)
    
    document.addEventListener("mousemove",this.handleMouse)
    document.addEventListener("mouseup",this.mouseEnd);
    document.addEventListener('touchmove',this.handleTouch);
  },
  destroyed(){
    document.removeEventListener("mousemove",this.handleMouse)
    document.removeEventListener("mouseup",this.mouseEnd);
    document.removeEventListener('touchmove',this.handleTouch);

  },
  watch:{
    active(newv){
      this.toggleColor(newv && this.value > 0)
    },
    value(newv){
      // if(newv==0||this.value ==0)
      {
        this.toggleColor(this.active && newv > 0)
      }
    }

  },
  methods:{
    handleChange(evnt){
     
      let val = parseInt(evnt.target.value)
     
      if(val<this.min||val>this.max||isNaN(val)){
        this.$refs.input.value = this.value
      }
    },
    handleInput(evnt){
      
      let val = parseInt(evnt.target.value)
      if(isNaN(val)){val = 0}
      if(val<this.min||val>this.max){return}
     
      this.$emit('input',val);
    },
    tickPos(tval){
      const val = tval-this.min;
      const total = this.max-this.min;
      const percent = Math.round((val/(total))*100);
      return {'left':percent+'%','bottom':'0%'};
    },
    toggleColor(newv){
      const sliderc = this.$el;
     
      if(newv){
        sliderc.style.setProperty('--scolor',this.scolor);
        sliderc.style.setProperty('--scolor2',this.scolor2);
      }else{
        sliderc.style.setProperty('--scolor','var(--gray)');
        sliderc.style.setProperty('--scolor2','var(--gray)');
      }
    },
    handleMouse(e){
      
      if(this.mdown)
      {
        const rect = this.slider.getBoundingClientRect();

        const width =  (rect.right - rect.left)
        const currPos = e.clientX - rect.left;

        let tvalue = (currPos/width);

        tvalue = Math.min(tvalue,1)
        tvalue = Math.max(tvalue,0)

        tvalue = this.min + (this.max-this.min) * tvalue;
        // if(this.step == 1){
        //   tvalue  = Math.round(tvalue);
        // }
        if(this.discrete){
          tvalue -= tvalue % this.step;
        }
        //this.value = tvalue;
        this.$emit('input',tvalue);
      }

    },
    handleTouch(ev){
        if(ev.touches.length){

        const e = ev.touches[0]
        this.handleMouse(e)
      }
    },
    touchStart(e){
      this.mdown = true;
      this.handleTouch(e);
      
    }
    ,
    mouseStart(e){
      this.mdown = true;
      
      this.handleMouse(e)
    },
    mouseEnd(){
      this.mdown = false;
    },
    
  },


}
</script>

<style>

  .tick-mark{
    width: 2px;
    height: 4px;
    background-color: var(--scolor);
  }

  .grip-line{
    width: 2px;
    height: 10px;
    background-color: whitesmoke;
    margin: 0px 2px;
  }

  .slider{
    /* --scolor: rgb(40,200,255); */
    --scolor: var(--blue);
    --scolor2: rgb(130, 175, 190);
    --gray: var(--mygray);

    user-drag:none;
    user-select: none;
  }
  .slider-label{
    color: var(--scolor);
    font-size: 0.8em;
  }
  .tick-text{
    color: var(--scolor);
    font-size: 0.9em;
    font-weight: 600;
    text-align: center;
  }
  .arrow-down{
    left: 50%;
    bottom: -0.39em;
    font-size: 3.5em;
    color: var(--scolor);
    opacity: 0.8;
    z-index:-1;
    transform: translateX(-50%);
  }
  .arrow-up{
    left: 50%;
    top: -0.46em;
    font-size: 3.5em;
    color: var(--scolor);
    z-index: -1;
    opacity: 0.8;
    transform: translateX(-50%);
  }
  .slider-line{

    background-color: var(--scolor);
    height: 2px;
  }
  .thumb{
    z-index: initial;
    width: 36px;
    height: 28px;
    border-radius: 4px;
    background-color: var(--scolor);
    margin-top: 10px;
    
  }
  .wrapper{
    z-index: 5;
  }
  .indicator{
    padding: 2px 0px;
    z-index: 5;
    width: 36px;
    background-color: var(--scolor);
    font-size: 0.9em;
  }
    
  .pl25{
    padding-left: 0.9em;
  }
  .slider-input{
    caret-color: var(--red);
    font-size: 1.0em;
    width: 2.6em;
    color: var(--scolor);
    border: 2px solid var(--scolor);
  }
  .slider-input:focus{
    outline-color: var(--blue);
    box-shadow: inset 0 0 4px var(--bluegray);
  }

  .center-item{
    transform: translateX(-50%);
  }
  .center-item2{
    transform: translateX(50%);
  }
  .slider-button{
    border: 2px solid var(--scolor);
    color: var(--scolor);
  }
  .slider-button:focus{
    outline: none;
  }

  /* 
  .arrow-down {
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  left: 12px;
  
  border-top: 20px solid var(--scolor);
} */


</style>