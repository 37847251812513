<template>
<div class="container-fluic">

    <div class="form-radio row justify-content-around mx-2" :class="className" >

      
            <button
                 v-for="(o,k) in value.options" :key="o.name"
                class="  mx-1 mb-2 mb-sm-2 form-radio-button py-2 px-4
                    
                text-uppercase  "
                :class="buttonClass(k)+' '+(stretch?'flex-grow-1':'')"
                :style="buttonStyle(o,k)"
                @click="value.selected = k"
                
            >

                <i class="fas fa-check mr-1" v-if="value.selected == k" >

                </i>
                <i class="fas fa-circle mr-1 text-black" v-else style="opacity:0.1"  >

                </i>
               
                {{o.name}}
                
            </button>
       

    </div>

</div>

</template>

<script>
export default {
    props:{
        size:{default:1.0},
        value:{type:Object,required:true},
        direction:{
            default:"row"
        },
        options:{
            type:Array,
        },
        stretch:{default:false}
        

    },
    data(){
        return{
           

        }
    },
    computed:{

        className(){
            // return ''
            
            return "flex-"+this.direction+"  "
            /* +(this.direction=='column'?'align-items-center':'align-items-stretch'); */
             
        },
    },
    methods:{
        
        buttonClass(k){
            const selected = k == this.value.selected;
            return selected ? " selected-radio" : " normal-radio";
        },
        imageStyle(k){
            const isInactive = k!=this.value.selected;
            return isInactive?{'filter':'grayscale(1)'}:{}
        },
        buttonStyle(o ){
            // const isActive = this.value.selected == k;
            let bcolor = 'var(--blue)';
            if(o.color){bcolor = `var(--${o.color})`}
            const style = {'background-color':bcolor,'color':'white','font-size':this.size-0.1+'em'}

            // if(o.color){
            //     style['background-color'] = this.value.selected == k ? o.color : 'lightgray';
            // }


            // style['background-color'] = isActive ? bcolor : 'transparent';
            // if(isActive){
            //     style['color'] = 'white';
            // }else{
            //     style['border'] = '2px solid '+bcolor;
            //     style['color'] =  bcolor;
            // }

             return style;
        }
    },
    mounted(){
      
        
    }

}
</script>

<style>
.form-radio{
    /* --blue: rgb(81, 199, 235); */
    --pink: pink;
    --active-color: var(--blue);
}
.normal-radio{
    color: whitesmoke;
    font-size: 1.0em;
     
    box-shadow: 0px 1px 4px gray;
    /* background-color: rgb(188, 193, 197); */
}
.selected-radio{
    /* background-color: var(--active-color); */
    /* background: linear-gradient(to top left,rgb(70, 165, 197),var(--blue)); */


    color: white;
    outline:none;
    border:none;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.445);
    text-shadow: 0 0 8px white;

}
.selected-radio:focus{
    outline: none;
}
.normal-radio:focus{
    outline: none;
}
.form-radio-button{
    font-weight: bold;
    border-radius: 4px;
    /* font-size: 1.2em; */
    border:none;
    /* box-shadow: 2px 2px 4px gray; */
    /* border: 2px solid lightslategray; */

}
/* @media screen and (min-width: 360px) {
    .form-radio-button{
        font-size: 2.3em;
    }
} */
.form-radio-image{
    height: 128px;
    width: 128px;
}

</style>