<template>
    <div  class="d-flex flex-grow-1 mb-3 align-items-start"
        :class="(horizontal?'':' flex-column ')+' '+(mini?'':'mt-3')"
    >
        <p class=" mb-2" 
            :class="mini?'text-bluegray small ':'text-bluegray mt-2'"
        >
            {{name}}
        </p>
        <slot/>
    </div>
</template>

<script>
export default {
    props:{
        name:{type:String,required:'true'},
        mini:{type:Boolean,default:false},
        horizontal:{type:Boolean,default:false},
    },

}
</script>

<style>

</style>