<template>
       <button   
            class="check-button  mr-2 rounded  outline-0 px-1 text-white fas  
            d-flex align-items-center justify-content-center"
            @click="$emit('input',!value)"
            :class="(value?'bg-pri-light  fa-check border-0':'bg-white border-gray ')+' '+
            (large?'large':'')"
                
        >
           
            
        </button>
</template>

<script>
export default {
    props:{
        value:{},
        large:{
            type:Boolean,
            default:false,
        }
    },


}
</script>

<style>
.check-button{
    height: 24px;
    width: 24px;
    /* border: 1px solid var(--darkgray); */
 }
 .check-button.large{
    height: 32px;
    width: 32px;
 }
</style>